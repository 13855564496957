import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { GlobalErrorHandlerService } from '../services/global-error-handler.service';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  errorHandleService = inject(GlobalErrorHandlerService);
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('/error')) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandleService.handleError(error);
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
